import {ContactForm, FullPhoto, Hero, Layout, Navigation, TextBox, WhatWeDid,} from "components/CaseLayout";
import SEO                                                                     from "components/seo";
import React                                                                   from "react";
import {useCaseData}                                                           from "shared/Hooks/CasesNew/useCaseData";
import {FARMA}                                                                 from "shared/Types/cases";
import {Header}                                                                from "../../components/CaseLayout";
import {ContentPhoto}                                                          from "../../components/CaseLayout";

const FarmaBialka = () => {
  const data = useCaseData(FARMA);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo} image={data.opengraph}/>
      <Hero data={data.hero}/>
      <WhatWeDid data={data.what_we_did}/>
      <Navigation type={FARMA}/>
      <TextBox data={data.text_boxes.first}/>
      <FullPhoto data={data.full_images.first}/>
      <FullPhoto data={data.full_images.second}/>
      <TextBox data={data.text_boxes.second}/>
      <FullPhoto data={data.full_images.third}/>
      <Header data={data.headers.first}/>
      <ContentPhoto data={data.content_images.first}/>
      <TextBox data={data.text_boxes.third}/>
      <ContentPhoto data={data.content_images.second}/>
      <Header data={data.headers.second}/>
      <ContentPhoto data={data.content_images.third}/>
      <FullPhoto data={data.full_images.fourth}/>
      <TextBox data={data.text_boxes.fourth}/>
      <ContentPhoto data={data.content_images.fourth}/>
      <FullPhoto data={data.full_images.fifth}/>
      <TextBox data={data.text_boxes.fifth}/>
      <ContentPhoto data={data.content_images.fifth}/>
      
      <ContactForm/>
    </Layout>
  );
};

export default FarmaBialka;
